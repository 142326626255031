/* eslint-disable max-len */
import React, { useMemo } from 'react';

import CollapsableCard from '../components/CollapsableCard';
import { css } from '@emotion/core';
import { Link, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import { useTargetZone } from '../hooks/useTargetZone';
import Skeleton from 'react-loading-skeleton';
import tableIcons from '../constants/tableIcons';
import { DateTime } from 'luxon';
import { TargetZoneStatus } from '../constants/targetZoneStatus';

const infoLink = ''; // TODO

const TargetZone = () => {
  const { loading, error, data: allData } = useTargetZone();

  const { lastCalculatedData, lastCalculatedDataIndex, data } = useMemo(() => {
    let foundIndex = -1;
    const foundItem = allData.find((d, index) => {
      if (typeof d.actualKwh === 'number' && !isNaN(d.actualKwh)) {
        foundIndex = index;
        return d;
      }
    });

    return {
      lastCalculatedData: foundItem,
      lastCalculatedDataIndex: foundIndex,
      data: allData,
    };
  }, [allData]);

  let messaging = null;
  if (lastCalculatedData) {
    if (lastCalculatedData.status === TargetZoneStatus.aboveTarget) {
      messaging = (
        <Grid item xs style={{ paddingBottom: '1rem' }}>
          <Typography>
            <b>Messaging:</b> Last month, your system’s energy production was above your Target Zone.
          </Typography>
        </Grid>
      );
    } else if (lastCalculatedData.status === TargetZoneStatus.onTarget) {
      messaging = (
        <Grid item xs style={{ paddingBottom: '1rem' }}>
          <Typography>
            <b>Messaging:</b> Last month, your system’s energy production was within your Target Zone.
          </Typography>
        </Grid>
      );
    } else {
      const prevItem = allData[lastCalculatedDataIndex + 1];
      const prevPrevItem = allData[lastCalculatedDataIndex + 2];
      if (
        prevItem &&
        prevItem.status === TargetZoneStatus.belowTarget &&
        prevPrevItem &&
        prevPrevItem.status === TargetZoneStatus.belowTarget
      ) {
        messaging = (
          <>
            <Grid item xs style={{ paddingBottom: '1rem' }}>
              <Typography>
                <b>Messaging:</b> Your system’s energy production has been lower than its design expectation for an
                extended period. Please take safety precautions when ensuring the panels are clean and not obstructed by
                shade.
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography>Please contact support to schedule a service appointment.</Typography>
            </Grid>
            <Grid item xs>
              <Typography>
                Reminder: Your system is backed by our Performance Guarantee. Refer to your agreement for details.
              </Typography>
            </Grid>
          </>
        );
      } else {
        messaging = (
          <>
            <Grid item xs style={{ paddingBottom: '1rem' }}>
              <Typography>
                <b>Messaging:</b> Last month, your system’s energy production was below the target zone. We are
                monitoring your system and may recommend a service appointment if the issue persists.
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography>
                Please take safety precautions when ensuring the panels are clean and not obstructed by shade.
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography>
                Reminder: Your system is backed by our Performance Guarantee. Refer to your agreement for details.
              </Typography>
            </Grid>
          </>
        );
      }
    }
  }

  return (
    <CollapsableCard
      title="App Target Zone: Customer-Facing"
      css={(theme) =>
        css`
          margin-bottom: ${theme.spacing(2)}px;
        `
      }
      initiallyExpanded={false}
      testId="target-zone-card"
    >
      {error ? (
        <Grid container direction="row" spacing={2} justifyContent="space-around">
          <Grid item xs={12} style={{ paddingRight: '2rem' }}>
            <Typography>Unknown error happened while fetching data</Typography>
          </Grid>
        </Grid>
      ) : loading ? (
        <Skeleton width={'100%'} height={400} />
      ) : allData.length === 0 ? (
        <Grid container direction="row" spacing={2} justifyContent="space-around">
          <Grid item xs={12} style={{ paddingRight: '2rem' }}>
            <Typography>No data available</Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="row" spacing={2} justifyContent="space-around">
          <Grid item xs={6} style={{ paddingRight: '2rem' }}>
            <Grid container alignItems="flex-start" direction="column" spacing={1}>
              <Grid item xs>
                <Typography>
                  This green band shows the expected normal range of production for your system. Because this is a
                  general trend showing the seasonality of solar production, it only appears on the year view.
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography>
                  <b>Location in app:</b> Log in and tap <b>Charts</b> from the menu, then <b> Solar energy produced</b>{' '}
                  and <b>Year</b>.
                </Typography>
              </Grid>
              <Grid item xs style={{ paddingTop: '1rem' }}>
                <Typography variant="h6">Last Month</Typography>
              </Grid>
              <Grid item xs>
                <Typography>
                  <b>Date:</b> {lastCalculatedData ? `${lastCalculatedData.month}` : 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs>
                <Grid container direction="row" spacing={3}>
                  <Grid item xs>
                    <Typography>
                      <b>Status:</b> {lastCalculatedData ? `${lastCalculatedData.status}` : 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid item xs={'auto'}>
                    <Typography>
                      <b>Actual Production:</b> {lastCalculatedData ? `${lastCalculatedData.actualKwh} kWh` : 'N/A'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {messaging}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Typography variant="h6">Full History</Typography>
              {infoLink ? (
                <Link
                  href={infoLink}
                  target="_blank"
                  css={css`
                    color: #0073e0;
                    font-size: 16px;
                    font-weight: 500;
                    text-decoration: none;
                    margin-left: 15px;
                    margin-top: 2px;
                  `}
                >
                  View Knowledge Article
                </Link>
              ) : null}
            </div>
            <MaterialTable
              title={null}
              columns={[
                {
                  title: 'Month',
                  field: 'month',
                  width: 1,
                  render: (dt) => dt.month,
                  headerStyle: {
                    paddingLeft: 0, // Remove horizontal padding from the header
                    paddingRight: 0,
                  },
                  cellStyle: {
                    paddingLeft: 0, // Remove horizontal padding from the cells
                    paddingRight: 0,
                  },
                  customSort: (dtA, dtB) => {
                    const dateA = DateTime.fromFormat(dtA.month, 'MMM yyyy');
                    const dateB = DateTime.fromFormat(dtB.month, 'MMM yyyy');

                    return dateA.toMillis() - dateB.toMillis();
                  },
                  defaultSort: 'desc',
                },
                { title: 'Actual kWh', field: 'actualKwh', width: 1 },
                {
                  title: 'Status',
                  field: 'status',
                  width: 1,
                },
              ]}
              data={data}
              localization={{ body: { emptyDataSourceMessage: 'No data' } }}
              options={{
                pageSize: 5,
                pageSizeOptions: [5, 10, 25],
                emptyRowsWhenPaging: false,
                paging: data.length > 5,
                draggable: false,
                search: false,
                showTitle: false,
                toolbar: false,
              }}
              icons={tableIcons}
              style={{
                backgroundColor: 'transparent', // Remove table's overall background
                boxShadow: 'none', // Remove table's shadow
              }}
            />
          </Grid>
        </Grid>
      )}
    </CollapsableCard>
  );
};

TargetZone.propTypes = {};

export default TargetZone;
