import { formatRoute } from 'react-router-named-routes';

/**
 * A `ROUTE` is a `PATH` with it's variables interpolated. For example:
 * PROSPECT_PATH = /site/:prospectId
 * PROSPECT_ROUTE = /site/12345
 *
 * You insert a `PATH` into the `path` prop like `<Route path={PATH}><Component /></Route>`
 * you insert a `ROUTE` into the `to` prop like `<Link to={ROUTE}>Go to route</Link>`
 */

export const DASH_BOARD_PATH =                  '/';
export const STATUS_TRACKER_PATH =  '/status-tracker/:surveyId/:prospectId/:contactId';

export const SITE_PATH =                        '/site/:prospectId';

export const SITE_DETAILS_PATH =                '/site/:prospectId/details';
export const SITE_DETAILS_OVERVIEW_PATH =       '/site/:prospectId/details/overview';
export const SITE_DETAILS_CONTACTS_PATH =       '/site/:prospectId/details/contacts';
export const SITE_DETAILS_PROJECT_STATUS_PATH = '/site/:prospectId/details/project-status';

export const SERVICE_CONTRACT_PATH =            '/site/:prospectId/service-contract';
export const SERVICE_CONTRACT_OVERVIEW_PATH =   '/site/:prospectId/service-contract/overview';
export const SERVICE_CONTRACT_PROMOTIONS_PATH = '/site/:prospectId/service-contract/promotions';

export const ACTIVITY_PATH =                    '/site/:prospectId/activity';

export const BILLING_PATH =                     '/site/:prospectId/billing';
export const BILLING_CONTACT_PATH =             '/site/:prospectId/billing/:contactId';
export const AUTOPAY_CONFIRMATION_PATH =        '/site/:prospectId/billing/:contactId/autopay-confirmation';
export const PAYMENT_CONFIRMATION_PATH = '/site/:prospectId/billing/:contactId/payment-confirmation';
export const LD_NOTES_PATH =                     '/site/:prospectId/billing/ld/notes';

export const PAYMENT_PATH =                     '/site/:prospectId/payment/:contactId';
export const PAYMENT_AUTOPAY_PATH =             '/site/:prospectId/payment/:contactId/autopay';
export const PAYMENT_ONE_TIME_PATH =            '/site/:prospectId/payment/:contactId/one-time-payment';
export const PAYMENT_SPECIAL_INVOICE_PATH =     '/site/:prospectId/payment/:contactId/special-invoice';

export const SYSTEM_PATH =                      '/site/:prospectId/system';
export const SYSTEM_OVERVIEW_PATH =             '/site/:prospectId/system/overview';
export const SYSTEM_PERFORMANCE_PATH =          '/site/:prospectId/system/performance';

export const DOCUMENTS_PATH =                   '/site/:prospectId/documents';
export const PROPOSAL_DOCUMENTS_PATH =          '/site/:prospectId/documents/proposal';
export const GENERAL_DOCUMENTS_PATH =           '/site/:prospectId/documents/general';
export const SERVICE_CONTRACT_DOCUMENTS_PATH =  '/site/:prospectId/documents/service-contract';
export const SOW_DOCUMENTS_PATH =               '/site/:prospectId/documents/sow';

export const HOME_ENERGY_REPORT_PATH =          '/site/:prospectId/home-energy-report';
export const HOME_ENERGY_REPORT_CONTACT_PATH =  '/site/:prospectId/home-energy-report/:contactId';
export const HOME_ENERGY_REPORT_LIST_PATH =     '/site/:prospectId/home-energy-report/reports';

export const pathToRoute = formatRoute;
