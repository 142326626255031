import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { gql, useLazyQuery } from '@apollo/client';

import { useParams } from 'react-router';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import mixpanel from '../utils/mixpanel';
import rollbar from '../utils/rollbar';
import useEnvConfig from '../hooks/useEnvConfig';
import { useHybridAuthToken } from '../components/hybridAuthToken';

const ACH_QUERY = gql`
  query ACHQuery($prospectId: String!) {
    prospect(prospectId: $prospectId) {
      id
      ACHEnabled
      ACHFee
    }
  }
`;

const DiscontinueAutopayModal = ({ isOpen, onClose }) => {
  const [discontinueRequested, setDiscontinueRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { paymentServiceAPIUrl } = useEnvConfig();
  const hybridAuth = useHybridAuthToken();
  const { prospectId } = useParams();
  const [updateACH, { error: ACHError }] = useLazyQuery(ACH_QUERY, {
    variables: { prospectId },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (discontinueRequested) {
      setIsLoading(true);
    }
  }, [discontinueRequested]);

  useEffect(() => {
    if (ACHError) {
      rollbar.error(`There was an issue with apollo client to updateACH for prospectId: ${prospectId}`);
    }
  }, [ACHError, prospectId]);

  useEffect(() => {
    if (isSuccess) {
      setIsLoading(false);
      updateACH();
      mixpanel.track('Autopay Discontinued', { prospectId });
    }

    if (hasError) {
      setIsLoading(false);
      mixpanel.track('User encountered discontinue autopay error', { prospectId });
    }
  }, [isSuccess, hasError, prospectId, updateACH]);

  const closeModal = () => {
    !isLoading && onClose();
  };

  const handleDiscontinueAutopay = async () => {
    setDiscontinueRequested(true);

    try {
      const response = await axios.post(
        `${paymentServiceAPIUrl}/v2/discontinue-autopay/${prospectId}`,
        {},
        { headers: { Authorization: hybridAuth } },
      );

      if (response.status === 200) {
        setIsSuccess(true);
      } else {
        rollbar.warn(`Discontinue autopay encountered an unhandled status: ${response.status} prospect: ${prospectId}`);
      }
    } catch (error) {
      setHasError(true);
      rollbar.error(`Discontinue autopay failure prospectId: ${prospectId} error: ${error}`);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">Discontinue Autopay</Typography>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          css={css`
            position: absolute;
            right: 8px;
            top: 8px;
            color: #9e9e9e;
          `}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        css={css`
          min-width: 336px;
          padding: 16px;
        `}
        dividers
      >
        {isLoading && (
          <div
            css={css`
              display: flex;
              justify-content: center;
            `}
          >
            <CircularProgress />
          </div>
        )}
        {!isLoading && hasError && (
          <DialogContentText color="textPrimary">Something went wrong. Try again later.</DialogContentText>
        )}
        {!isLoading && isSuccess && (
          <>
            <DialogContentText color="textPrimary">Autopay discontinued.</DialogContentText>
          </>
        )}
        {!discontinueRequested && (
          <DialogContentText color="textPrimary">Are you sure you want to discontinue autopay?</DialogContentText>
        )}
      </DialogContent>
      <DialogActions
        css={css`
          margin: 0;
          padding: 8px;
        `}
      >
        <Button
          aria-label="proceed to unenroll from autopay"
          autoFocus
          onClick={handleDiscontinueAutopay}
          color="primary"
          disabled={discontinueRequested}
        >
          Discontinue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DiscontinueAutopayModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DiscontinueAutopayModal;
