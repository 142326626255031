import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import useEnvConfig from './useEnvConfig';
import { useParams } from 'react-router';
import { useHybridAuthToken } from '../components/hybridAuthToken';
import { gql, useQuery } from '@apollo/client';
import { dataHooks } from '@sunrun/recent-performance-charts';
import { DateTime } from 'luxon';
import { TargetZoneStatus } from '../constants/targetZoneStatus';

const TARGET_ZONE_CARD_QUERY = gql`
  query TargetZoneCardQuery($prospectId: String) {
    prospect(prospectId: $prospectId) {
      PTO
      timeZone
    }
  }
`;

export const useTargetZone = () => {
  const { performanceChartsBaseURL } = useEnvConfig();
  const { prospectId } = useParams();
  const authToken = useHybridAuthToken();
  const {
    loading: loadingQuery,
    data: queryData,
    error: queryError,
  } = useQuery(TARGET_ZONE_CARD_QUERY, {
    variables: { prospectId },
    returnPartialData: true,
  });

  const { startISO, endISO } = useMemo(() => {
    const now = DateTime.fromObject({}, { zone: queryData?.prospect?.timeZone || 'local' });

    const startDate = queryData?.prospect?.PTO
      ? DateTime.fromFormat(queryData?.prospect?.PTO, 'yyyy-MM-dd', {
          zone: queryData?.prospect?.timeZone || 'local',
        }).toISO()
      : now.startOf('year').toISO();

    return { startISO: startDate, endISO: now.endOf('year').toISO() };
  }, [queryData?.prospect?.PTO, queryData?.prospect?.timeZone]);

  // before we pull we want to make sure the correct timezone is set if available
  const monthlyPerformance = dataHooks.useMonthlyPerformance(
    prospectId,
    startISO,
    endISO,
    performanceChartsBaseURL,
    queryData ? authToken : null,
  );

  const monthlyPerformanceLoading = dataHooks.useMonthlyPerformanceLoading();
  const monthlyPerformanceError = dataHooks.useMonthlyPerformanceError();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      if (!data && prospectId && queryData?.prospect?.PTO && authToken && !loading && !error) {
        try {
          setLoading(true);
          const res = await axios.get(
            `${performanceChartsBaseURL}/site-contracted-kwh/${prospectId}?ptoDate=${queryData?.prospect?.PTO}`,
            {
              headers: {
                Authorization: authToken,
              },
            },
          );

          if (res.status === 200) {
            setData(res.data);
          } else {
            throw new Error('Could not get contracted kwh from performance api');
          }
        } catch (e) {
          setError(true);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [prospectId, data, performanceChartsBaseURL, authToken, queryData?.prospect?.PTO, loading, error]);

  const calculated = useMemo(() => {
    const now = DateTime.fromObject({}, { zone: queryData?.prospect?.timeZone || 'local' })
      .startOf('month')
      .toISODate();

    return (
      data?.contractedKwh
        ?.filter((d) => now >= d.timestamp)
        .map((d) => {
          const production = (monthlyPerformance || []).find((p) => p.timestamp === d.timestamp);
          const productionValue = Math.floor(production?.solar);
          const isProductionValueValid = !isNaN(productionValue);

          const currentMonth = DateTime.fromISO(d.timestamp, { zone: queryData?.prospect?.timeZone || 'local' })
            .startOf('month')
            .toISODate();
          const isCurrentMonth = now === currentMonth;

          return {
            month: DateTime.fromISO(d.timestamp, { zone: queryData?.prospect?.timeZone || 'local' }).toLocaleString({
              month: 'short',
              year: 'numeric',
            }),
            actualKwh: isCurrentMonth ? 'TBD' : !isProductionValueValid ? 'N/A' : productionValue,
            status:
              isProductionValueValid && !isCurrentMonth
                ? productionValue > d.min
                  ? productionValue > d.max
                    ? TargetZoneStatus.aboveTarget
                    : TargetZoneStatus.onTarget
                  : TargetZoneStatus.belowTarget
                : '--',
          };
        }) || []
    ).reverse();
  }, [data?.contractedKwh, monthlyPerformance, queryData?.prospect?.timeZone]);

  return {
    loading: loading || loadingQuery || !!monthlyPerformanceLoading,
    error: error || !!queryError || !!monthlyPerformanceError,
    data: calculated,
  };
};
