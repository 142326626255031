import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { gql, useLazyQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { BILLING_CONTACT_PATH, pathToRoute } from '../../constants/routes';

const ACH_QUERY = gql`
  query ACHQuery($prospectId: String!) {
    prospect(prospectId: $prospectId) {
      id
      ACHEnabled
      ACHFee
    }
  }
`;

export const AutopayConfirmationModal = ({ paymentMethod, lastFour }) => {
  const { prospectId, contactId } = useParams();
  const history = useHistory();
  const [updateACH] = useLazyQuery(ACH_QUERY, {
    variables: { prospectId },
    fetchPolicy: 'network-only',
  });

  const closeModal = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      event.preventDefault();
      return;
    }

    updateACH();
    history.replace(pathToRoute(BILLING_CONTACT_PATH, { prospectId, contactId }));
  };

  return (
    <Dialog
      open={true}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
      data-testid="autopay-confirmation-modal"
    >
      <>
        <DialogTitle id="form-dialog-title">Autopay Confirmed</DialogTitle>
        <DialogContent>
          <DialogContentText color="textPrimary">
            Enrolled with {paymentMethod} ending in {lastFour}
          </DialogContentText>
        </DialogContent>
      </>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AutopayConfirmationModal.propTypes = {
  paymentMethod: PropTypes.string.isRequired,
  lastFour: PropTypes.string.isRequired,
};
